export default {
  breakpoints: [
    '640px', '768px', '1024px', '1280px'
  ],
  space: [
    0,
    2,
    4,
    8,
    16,
    32,
    64,
    128,
    256,
    512
  ],
  fonts: {
    body: 'Avenir, sans-serif',
    heading: 'Avenir, sans-serif',
    monospace: 'Menlo, monospace'
  },
  fontSizes: [
    12,
    16,
    24,
    32,
    40,
    48,
    56,
    64,
    72
  ],
  fontWeights: {
    body: 300,
    heading: 700,
    bold: 700
  },
  lineHeights: {
    body: 1.5,
    heading: 1.125
  },
  sizes: {
    default: "90vw",
    xsmall: "375px",
    small: "640px",
    medium: "768px",
    large: "1024px",
    max: "1280px",
  },
  layout: {
    container: {
      width: '100%',
      maxWidth: 'max',
      mx: 'auto'
    },
    medium: {
      width: '100%',
      maxWidth: 'medium',
      px: 4,
      mx: 'auto'
    }
  },
  colors: {
    text: '#000',
    background: '#fff',
    primary: '#7e57c2',
    secondary: '#AC93F8',
    muted: '#f6f6f6',
    dark: '#000',
    light: '#fff',
    lightGray: '#fafafa',
    gray: "#e0e0e0",
  },
  shadows: {
    small: "0 1px 2px 0 rgba(0, 0, 0, 0.05)",
    regular: "0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06)",
    medium: "0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)",
    large: "0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05)"
  },
  radii: {
    small: "1px",
    regular: "3px",
    medium: "6px",
    round: "99px",
  },
  buttons: {
    primary: {
      px: 4,
      py: 4,
      fontWeight: 'heading',
      fontFamily: 'heading',
      color: 'white',
      borderRadius: '3px',
      bg: 'primary',
      cursor: 'pointer',
    },
    secondary: {
      px: 4,
      py: 4,
      fontWeight: 'heading',
      fontFamily: 'heading',
      color: 'primary',
      borderRadius: '3px',
      bg: 'secondary',
      cursor: 'pointer',
    },
    rounded: {
      px: 5,
      py: 4,
      fontWeight: 'heading',
      fontFamily: 'heading',
      color: 'white',
      borderRadius: '99px',
      bg: 'primary',
      cursor: 'pointer',
    },
    nav: {
      px: 3,
      py: 2,
      fontWeight: 'heading',
      fontFamily: 'heading',
      color: 'primary',
      borderRadius: '3px',
      bg: 'light',
      cursor: 'pointer',
    },
  },
  forms: {
    label: {
      fontSize: 1,
      fontWeight: 'bold',
    },
    input: {
      borderColor: 'gray',
      borderRadius: 'regular',
      backgroundColor: 'light',
      borderRadius: '3px',
      p: 4,
      '&:focus': {
        borderColor: 'primary',
        boxShadow: 'medium',
        outline: 'none',
      },
    },
    select: {
      backgroundColor: 'white',
      borderColor: 'gray',
      borderRadius: 'regular',
      width: "100%",
      my: 4,
      p: 4,
      '&:focus': {
        borderColor: 'primary',
        boxShadow: 'medium',
        outline: 'none',
      },
    },
    textarea: {
      borderColor: 'gray',
      borderRadius: 'regular',
      backgroundColor: 'light',
      '&:focus': {
        borderColor: 'primary',
        boxShadow: 'medium',
        outline: 'none',
      },
    },
    slider: {
      bg: 'muted',
    },
  },
  styles: {
    root: {
      fontFamily: 'body',
      lineHeight: 'body',
      fontWeight: 'body'
    },
    h1: {
      color: 'dark',
      fontFamily: 'heading',
      lineHeight: 'heading',
      fontWeight: 'bold',
      fontSize: [5,5,6,6],
      mt: "0px",
      light: {
        color: 'light'
      }
    },
    h2: {
      color: 'dark',
      fontFamily: 'heading',
      lineHeight: 'heading',
      fontWeight: 'body',
      fontSize: [4,4,5,5],
      mt: "0px",
      light: {
        color: 'light'
      }
    },
    h3: {
      color: 'text',
      fontFamily: 'heading',
      lineHeight: 'heading',
      fontWeight: 'bold',
      fontSize: [3,3,4,4],
      light: {
        color: 'light'
      }
    },
    h4: {
      color: 'text',
      fontFamily: 'heading',
      lineHeight: 'heading',
      fontWeight: 'body',
      fontSize: [2,2,3,3],
      light: {
        color: 'light'
      }
    },
    h5: {
      color: 'text',
      fontFamily: 'body',
      lineHeight: 'body',
      fontWeight: 'body',
      fontSize: [1,1,2,2],
      light: {
        color: 'light'
      }
    },
    h6: {
      color: 'text',
      fontFamily: 'body',
      lineHeight: 'body',
      fontWeight: 'body',
      fontSize: [0,1,1],
      light: {
        color: 'light'
      }
    },
    p: {
      color: 'text',
      fontFamily: 'body',
      fontWeight: 'body',
      lineHeight: 'body',
      fontSize: [1],
      light: {
        color: 'light'
      },
    },
    a: {
      color: 'primary',
      fontFamily: 'body',
      fontWeight: 'heading',
      lineHeight: 'body',
      textDecoration: 'none',
      cursor: 'pointer'
    },
    pre: {
      fontFamily: 'monospace',
      overflowX: 'auto',
      code: {
        color: 'inherit'
      }
    },
    li: {
      color: 'text',
      fontFamily: 'body',
      fontWeight: 'body',
      lineHeight: 'body',
      fontSize: [0],
      light: {
        color: 'light'
      },
      header: {
        fontFamily: 'heading',
      }
    },
    code: {
      fontFamily: 'monospace',
      fontSize: 'inherit'
    },
    img: {
      maxWidth: '100%'
    }
  }
}
/** @jsx jsx */
import { jsx, Grid, Container, Flex, Styled, Box, Input } from 'theme-ui'
import React, { useContext} from 'react'
import { Link, navigate } from 'gatsby'

import { FirebaseContext } from '../../context'
import algoliasearch from 'algoliasearch'

import AlgoliaLogo from '../../images/algolia.svg'
import Logo from "../../images/barkpedia-logo.svg"
import Bobby from "../../images/bobby-beagle.svg"
import Menu from "../../images/menu.svg"

import {
  InstantSearch,
  connectAutoComplete, 
  Configure,
} from 'react-instantsearch-dom'

const searchClient = algoliasearch('S915OEFHS6', '8aba62ee96297963d80ba21c8c903d97');

export const Header = () => {

  const { user, firebase } = useContext(FirebaseContext)
  
  const Logout = () => {
    firebase.logout().then(() => navigate('/'))
  }

  const Autocomplete = ({ hits, currentRefinement, refine }) => (
    <Box sx={{position: 'relative'}}>
      <Input
        type="search"
        placeholder="Search Breeds..."
        value={currentRefinement}
        onChange={event => refine(event.currentTarget.value)}
      />
      {hits.length > 0 && currentRefinement != "" && (
        <Box sx={{position: 'absolute', top: '70px', left: '0px', right: '0px', width: '100%', backgroundColor: '#fafafa', borderRadius: 'regular', border: '1px solid #e0e0e0', p: 3, }}>
          {hits.map(hit => (
            <Box>
              <Styled.a as={Link} to={`/breeds/${hit.slug.current}`} key={hit.objectID}>{hit.title}</Styled.a>
            </Box>
          ))}
          <Box sx={{mt: 4}}>
            <img src={AlgoliaLogo} />
          </Box>
        </Box>
      )}
    </Box>
  );
  
  const CustomAutocomplete = connectAutoComplete(Autocomplete);


  return (
    <>

      <Grid columns={[1,1,3]} sx={{px: 4, backgroundColor: "primary", height: "30px", alignItems: "center", justifyContent: "center", position: "sticky", top: "0", left: "0", right: "0", width: "100%", zIndex: "3000"}}>
        <Flex sx={{width: '100%', display: ['none', 'none', 'flex']}}>

        </Flex>
        <Flex sx={{width: '100%', display: ['none', 'none', 'flex']}}>
          
        </Flex>
        <Flex sx={{width: '100%', justifyContent: 'flex-end'}}>
          {user && user.email && (
            <div>
              <Link to={'/profile'} sx={{color: 'white', mr: 4, fontSize: '14px'}}>{`@${user.username}` || user.email}</Link>
              <Styled.a onClick={Logout} sx={{color: 'white', fontSize: '14px'}}>Logout</Styled.a>
            </div>
          )}
          
          {!user && (
            <div>
              <Link sx={{color: 'white', fontSize: '14px', textDecoration: 'none', mr: 4}} to="/sign-up">Sign Up</Link>
              <Link sx={{color: 'white', fontSize: '14px', textDecoration: 'none'}} to="/sign-in">Login</Link>
            </div>
          )}
        </Flex>
      </Grid>
      <Flex sx={{backgroundColor: "#fafafa", p: 4, position: "sticky", top: "30px", left: "0", right: "0", width: "100%", zIndex: "3000",  display: ["flex", "flex","flex"]}}>
        <Container>
          <Grid columns={[2,2,3]} gap={0} sx={{gridAutoRow: "1fr", width: '100%'}}>
            <Flex sx={{alignItems: "center", justifyContent: ["flex-start"], display: ['none','none','flex'],  width: '100%'}}>
              <Styled.a as={Link} to="/breeds" sx={{color: 'dark', fontSize: [1], display: ['none','none','block'], mr: 5}}>Dog Breeds</Styled.a>
              <Styled.a as={Link} to="/breeders" sx={{color: 'dark', fontSize: [1], display: ['none','none','block']}}>Breeders</Styled.a>
            </Flex>
            <Flex sx={{width: "100%", justifyContent: ["flex-start","flex-start","center"], alignItems: "center"}}>
              <Styled.a as={Link} to="/"><img src={Logo} width="175px" sx={{display: ['none','none','block'],mb: '0px'}}/></Styled.a>
              <Flex sx={{display: ['flex','flex','none'], alignItems: 'center', mb: '0px'}}>
                <img src={Menu} width="18px" sx={{mb: '0px', mr: 4}}/>
                <Styled.a as={Link} to="/"><img src={Bobby} width="35px" sx={{mb: '0px'}}/></Styled.a>
              </Flex>
            </Flex>
            <Flex sx={{width: "100%", justifyContent: ["flex-end"], alignItems: "center"}}>
              {/* <Button as={Link} variant="nav" sx={{ display: ["none", "none","block"]}} to="breed-quiz">Dog Quiz →</Button> */}
              <InstantSearch
                searchClient={searchClient}
                indexName="Breeds"
              >
                <Configure hitsPerPage={10} />
                <CustomAutocomplete defaultRefinement="" />
              </InstantSearch>
            </Flex>
          </Grid>
        </Container>
      </Flex>
  </>
  )
}
import firebaseConfig from "./config";

class Firebase {
  constructor(app) {
    if(!firebaseInstance) {
      app.initializeApp(firebaseConfig);

      this.auth = app.auth();
      this.db = app.firestore();
      this.functions = app.functions();
      this.storage = app.storage();
      this.google = new app.auth.GoogleAuthProvider();
    }
  }

  async getUserProfile({userId}) {
    return this.db.collection('userProfiles').where("userId", "==", userId).get()
  }


  async getUserProfileData({userId}) {
    return this.db.collection('userProfiles').where("userId", "==", userId).get()
  }

  async register({username, email, password, breeder, breederId}) {
    const newUser = await this.auth.createUserWithEmailAndPassword(email,password);
    return this.db.collection('userProfiles').doc(username).set({
      userId: newUser.user.uid,
      breeder: breeder,
      breederId: breederId
    })
  }

  async login({email, password}) {
    return this.auth.signInWithEmailAndPassword(email, password);
  }

  async logout() {
    await this.auth.signOut();
  }

  async signInWithGoogle() {
    await this.auth.signInWithPopup(this.google)
  }

  async followBreed(user, breed) {
    await this.db.collection('userBreeds').doc().set({
      userID: user.uid,
      breedID: breed.id,
      breedTitle: breed.title
    })
  }

  async getFollowing(user, breed) {
    return this.db.collection('userBreeds').where('userID', '==', user.uid).where('breedID', '==', breed.id).get()
  }
}

let firebaseInstance;

function getFirebaseInstance(app) {
  if(!firebaseInstance && app){
    firebaseInstance = new Firebase(app);
    return firebaseInstance;
  }else if(firebaseInstance){
    return firebaseInstance
  }else{
    return null;
  }
}

export default getFirebaseInstance;

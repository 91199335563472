import React, { useState } from 'react'

import { FirebaseContext } from '../context'
import { useAuth } from '../components/Firebase'

export const FirebaseProvider = ({ children }) => {

  const {user,firebase,loading} = useAuth();

  return (
    <FirebaseContext.Provider value={{user, firebase, loading}}>
      {children}
    </FirebaseContext.Provider>
  )
}
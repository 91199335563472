/** @jsx jsx */
import React, { createRef } from 'react'
import { jsx, Box, Grid, Container, Flex, Styled, Button, Input } from 'theme-ui'
import { Link } from 'gatsby'
import Bark from "../../images/bark.mp3"


export const Footer = () => {

  const bark = createRef()

  const formSubmit = () => {
    bark.current.play()
  }
  
  return (
    <>
      <Flex sx={{backgroundColor: 'primary', px: 5, pt:6, pb: 3}}>
        <Container>
          <Grid columns={[1,2,3,3]} gap={5} sx={{gridAutoRow: "1fr", width: '100%'}}>
            <Box sx={{width: '100%'}}>
              <Styled.a as={Link} to="/" sx={{color: 'white'}}>Barkpedia<small>&trade;</small></Styled.a>
              <Styled.p sx={{color: 'white'}}>Know Your Breed before You Buy (or Adopt).</Styled.p>
            </Box>
            <Flex sx={{width: "100%", justifyContent: "center"}}>
            </Flex>
            <Flex sx={{width: "100%", justifyContent: ["flex-end"], alignItems: "center"}}>
              <Box sx={{width: '100%'}}>
                <Styled.h6 sx={{color: 'white'}}>Breeds in your (in)box!</Styled.h6>
                <form name="join-list" data-action="/get-started" method="post" data-netlify="true" data-netlify-honeypot="bot-field" sx={{display: "flex", zIndex: "10", backgroundColor: "light", minWidth: ["300px"], flexWrap: ["wrap", "wrap", "nowrap"], justifyContent: "space-between"}}>
                  <input type="hidden" name="form-name" value="join-list" />
                  <input name="email" type="email" sx={{minWidth: ["200px"], width: "100%", border: "none", px: 4, m: 4, fontSize: [0,0,1]}} placeholder="What's your email?" />
                  <Button variant="secondary" onClick={e => formSubmit()} sx={{m: 2, minWidth: "70px", width: "100%",}}>Join Us</Button>
                  <audio ref={bark} src={Bark} preload="true" />
                </form> 
              </Box>
            </Flex>
          </Grid>
          <Flex sx={{mx: 'auto', width: '100%', maxWidth: '225px', justifyContent: 'space-between', mt: 5}}>
            <Styled.a as={Link} to="/" sx={{color: 'white', fontSize: '10px'}}>Privacy</Styled.a>
            <Styled.a as={Link} to="/" sx={{color: 'white', fontSize: '10px'}}>Terms & Conditions</Styled.a>
            <Styled.a as={Link} to="/" sx={{color: 'white', fontSize: '10px'}}>Contact</Styled.a>
          </Flex>
        </Container>
      </Flex>
  </>
  )
}
const firebaseConfig = {
  apiKey: "AIzaSyDO8AazdudPbJsCe1euL8ZxpmVhvv3Xfg0",
  authDomain: "barkpedia-b2e72.firebaseapp.com",
  databaseURL: "https://barkpedia-b2e72.firebaseio.com",
  projectId: "barkpedia-b2e72",
  storageBucket: "barkpedia-b2e72.appspot.com",
  messagingSenderId: "224203924513",
  appId: "1:224203924513:web:cd3333a28ec1fa2b636f60"
};


export default firebaseConfig;